<template>
  <div class="d-flex justify-center align-center index-wrap">
    <div class="card-wrap">
      <div class="d-flex justify-space-between align-center mb-8 mb-md-12 mx-4 mt-4">
        <v-spacer class="d-none d-md-block"></v-spacer>
        <v-spacer></v-spacer>
        <div class="title-text rem-12 rem-md-20 font-weight-bold">
          立 即 儲 值
        </div>
        <v-spacer></v-spacer>
        <div class="close-btn d-flex justify-center align-center can-click" @click="$router.go(-1)">
          <v-icon color="#363E4B" large class="icon-close">mdi-close</v-icon>
        </div>
      </div>

      <div class="content-wrap white--text rem-18 rem-md-28 mx-11 py-9 text-center mb-12 mb-md-15">
        {{ $route.query.amount }} TBT
      </div>

      <div class="action-wrap white d-flex flex-column justify-center align-center py-4 py-md-5">
        <v-btn
          v-if="allowance === 0 || allowance < balance"
          class="mb-3 mb-md-5 white--text confirm-btn"
          :style="{ background: 'linear-gradient(to right, #27ABC8, #01094D)' }"
          @click="approve()"
        >
          解鎖
        </v-btn>
        <v-btn
          v-else
          class="mb-3 mb-md-5 white--text confirm-btn"
          :style="{ background: 'linear-gradient(to right, #27ABC8, #01094D)' }"
          @click="store()"
        >
          確定儲值
        </v-btn>
        <div class="return-text rem-2 rem-md-6 can-click" @click="$router.go(-1)">回上一頁</div>
      </div>
    </div>
    <loading :loadingShow="loadingShow" :content="loadingText"></loading>
  </div>
</template>
<script>
import base from '@/mixin/base'
import loading from '@/components/loading'
export default {
  name: 'Index',
  mixins: [base],
  data(){
    return {
      balance: 0,
      allowance: 0,
      loadingText: '',
      loadingShow: false,
      timer: null,
    }
  },
  components: {
    loading
  },
  methods: {
    async store() {
      if (!window.ethereum) {
        this.$toasted.error('請安裝 MetaMask')
      } else if (!window.ethereum.selectedAddress) {
        await this.connectMetamask()
      } else if ((window.ethereum.selectedAddress).toLowerCase() !== (this.$route.query.user_address).toLowerCase()) {
        this.$toasted.error('Metamask 連接帳號與儲值帳號不符')
      } else if (parseInt(this.$route.query.amount) > this.balance) {
        this.$toasted.error('餘額不足')
      } else {
        if (this.$store.state.chainId){
          let result = await this.$tbtConsump.deposit(parseInt(this.$route.query.amount))
          console.log('result', result)
          if (result.txHash){
            this.$toasted.show('交易已送出')
            if (this.$route.query.return_url){
              await this.$store.dispatch('returnApi', {
                url: this.$route.query.return_url,
                data: {
                  user_address: this.$route.query.user_address,
                  tx: result.txHash
                }
              })
              
              window.location.href = this.$route.query.return_url
            }
          }else if (result.code === 4001){
            this.$toasted.error('使用者拒絕連線')
          }
        }else{
          this.$toasted.error('請切換到幣安智能鏈')
        }
      }
    },
    async approve(){
      if (!window.ethereum) {
        this.$toasted.error('請安裝 MetaMask')
      } else if (!window.ethereum.selectedAddress) {
        await this.connectMetamask()
      } else if ((window.ethereum.selectedAddress).toLowerCase() !== (this.$route.query.user_address).toLowerCase()) {
        this.$toasted.error('Metamask 連接帳號與儲值帳號不符')
      } else {
        if (this.$store.state.chainId){
          let result = await this.$tbt.approve()
          console.log('result', result)
          if (result.txHash){
            this.loadingText = '解鎖中，請稍後'
            this.loadingShow = true
            this.timer = window.setInterval(async () => {
              await this.getAllowance()
              if (this.allowance >= this.balance) {
                window.clearInterval(this.timer)
                this.loadingShow = false
              }
            }, 1000)
            this.$toasted.show('交易已送出')
          }else if (result.code === 4001){
            this.$toasted.error('使用者拒絕連線')
          }
        }else{
          this.$toasted.error('請切換到幣安智能鏈')
        }
      }
    },
    async getBalance(){
      try{
        this.balance = await this.$tbt.getBalance(this.$route.query.user_address)
      }catch(error){
        console.log('error', error)
      }
    },
    async getAllowance(){
      try{
        this.allowance = await this.$tbt.getAllowance(this.$route.query.user_address)
      }catch(error){
        console.log('error', error)
      }
    },
  },
  async mounted(){
    if (this.$route.query.amount){
      await this.getBalance()
      await this.getAllowance()
    }
  }
}
</script>
<style lang="scss">
.index-wrap {
  width: 100vw;
  height: 100%;
  padding: 20px 0;
  .card-wrap {
    background: #363E4B;
    max-width: 430px;
    width: 90%;
    border-radius: 38px;
    position: relative;
    overflow: hidden;
    .close-btn {
      width: 47px;
      height: 47px;
      border-radius: 50%;
      background: #656C76;
      @include dai_vuetify_md {
        width: 35px;
        height: 35px;
      }
      .icon-close {
        @include dai_vuetify_md {
          font-size: 24px !important;
        }
      }
    }
    .title-text {
      color: #FFAD00;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }
    .content-wrap {
      background: rgba(217, 217, 217, .1);
      border-radius: 17px;
      @include dai_vuetify_md {
        margin-left: 80px !important;
        margin-right: 80px !important;
      }
    }
    .confirm-btn {
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.22);
      border-radius: 12px;
      width: calc(100% - 60px);
      font-size: 27px;
      padding: 35px 0 !important;
      @include dai_vuetify_md {
        font-size: 20px;
        padding: 30px 0 !important;
      }
    }
    .return-text {
      color: #9A9A9A;
    }
  }
}
</style>